import React from "react"
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  Container,
  ChakraBaseProvider,
  theme
} from "@chakra-ui/react"
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi"
// And react-slick as our Carousel Lib
import Slider from "react-slick"

import canada from './data/img/image07032023.jpg'
import congo from './data/img/image07012023.jpg'
import world from './data/img/world_2000.png'
import russia from './data/img/russia_2020157.png'
// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default function CaptionCarousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  //const top = useBreakpointValue({ base: "90%", md: "50%" })
  //const side = useBreakpointValue({ base: "30%", md: "40px" })


  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      title: "Burn Scars in Eastern Canada",
      text:
        "MODIS Land Rapid Response Team, NASA GSFC",
      image:
        canada
    },
    {
      title: "Fires in the Democratic Republic of Congo and Zambia",
      text:
        "MODIS Land Rapid Response Team, NASA GSFC",
      image:
        congo
    },
    // {
    //   title: "First Complete Day of MODIS",
    //   text:
    //     "MODIS Atmosphere Team, NASA GSFC",
    //   image:
    //     world
    // }
    {
      title: "Burn Scar in Sakha Republic",
      text:
        "Harmonized Landsat and Sentinel-2 (HLS) Imagery",
      image:
        russia

    }
  ]

  return (
    <ChakraBaseProvider theme={theme}>
    <Box
      position={"relative"}
      height={"600px"}
      width={"full"}
      overflow={"hidden"}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={{base: "30%", md: "40px"}}
        top={{base: "90%", md: "50%"}}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={{base: "30%", md: "40px" }}
        top={{base: "90%", md: "50%"}}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={slider => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            height={"6xl"}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${card.image})`}
          >
            {/* This is the block you need to change, to customize the caption */}
            <Container size="container.lg" height="300px" position="relative" color="gray.200">
              <Stack
                spacing={6}
                w={"full"}
                maxW={"lg"}
                position="absolute"
                top="50%"
                transform="translate(0, -50%)"
                
              >
                <Box bg="rgba(133,133,133,0.4)" borderRadius='md'>
                <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }} >
                  {card.title}
                </Heading>
                </Box>
                <Text fontSize={{ base: "md", lg: "lg" }}>
                  {card.text}
                </Text>
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
    </ChakraBaseProvider>
  )
}