import React from 'react'
import PubsGrid from './components/PubsGrid.js'
//import Map from './components/Map.js'
import PubsHeader from './components/header/PubsHeader.js'
import Footer from './components/Footer.js';

function PublicationsPage() {
  return (
    <div
      sx={{
        '& .scrollDiv': {
          height: 'auto',
          maxHeight: '100%',
          overflow: 'auto',
        },
      }}
    >
      <PubsHeader />
      <br />
      <PubsGrid />
      <Footer />
    </div>
  );
}

export default PublicationsPage;