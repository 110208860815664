"use client"
import React from "react"
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  ChakraProvider
} from "@chakra-ui/react"
import {
  FaFire,
  FaDownload,
  FaBinoculars,
} from "react-icons/fa"
import {
    FiMenu
} from "react-icons/fi"

const LinkItems = [
  { name: "Active Fire Products", icon: FaFire, nav: "/viirs-af" },
  { name: "Burned Area Product", icon: FaFire, nav: "/viirs" },
  { name: "Access the Data", icon: FaDownload, nav: "/viirs" },
  { name: "FIRMS View", icon: FaBinoculars, nav: 'https://firms.modaps.eosdis.nasa.gov/map/' },
]

export default function VIIRSsideBar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <ChakraProvider>
            <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
                <SidebarContent
                    onClose={() => onClose}
                    display={{ base: "none", md: "block" }}
                />
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full"
                >
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
                <Box ml={{ base: 0, md: 60 }} p="4">
                    {/* Content */}
                </Box>
            </Box>
        </ChakraProvider>
    )
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.900")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="relative"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="10" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          explore
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem key={link.name} icon={link.icon} nav={link.nav}>
          {link.name}
          
        </NavItem>
      ))}
    </Box>
  )
}

const NavItem = ({ icon, children, nav, ...rest }) => {
    const handleClick = () =>{
        window.location.href = nav;
    }
  return (
    <Box
      as="div"
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "green.400",
          color: "white"
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white"
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        explore
      </Text>
    </Flex>
  )
}
