import React from 'react';
import Footer from './components/Footer.js';
import VIIRSHeader from './components/header/VIIRSHeader.js';
import VIIRSsideBar from './components/viirs/VIIRSsideBar.js';
import VIIRSAFProducts from './components/viirs/VIIRSAFProducts.js';

import { Grid, GridItem } from '@chakra-ui/react'




function VIIRSPage() {
  return (
    <div
      // sx={{
      //   '& .scrollDiv': {
      //     height: 'auto',
      //     maxHeight: '100%',
      //     overflow: 'auto',
      //   },
      // }}
    >

      <Grid
        h='full'
        templateRows='repeat(1, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
      >
        <GridItem rowSpan={1} colSpan={1}> <VIIRSsideBar /> </GridItem>
        <GridItem colSpan={4}> <VIIRSAFProducts/> </GridItem>
      </Grid>
      <Footer />
    </div>
  );
}

export default VIIRSPage;
