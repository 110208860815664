import React from 'react';
import TeamCard from './components/TeamCard.js';
import TeamHeader from './components/header/TeamHeader.js';
import Footer from './components/Footer.js';
import AdjunctTeamHeader from './components/header/AdjunctTeamHeader.js';
import AdjunctTeamCard from './components/AdjunctTeamCard.js';
//import TeamCardColabs from './components/TeamCardColabs.js';

function TeamPage() {
  return (
    <div>
      <TeamHeader />
      <TeamCard />
      <AdjunctTeamHeader />
      <AdjunctTeamCard />
      <Footer />
    </div>
  );
}

export default TeamPage;
