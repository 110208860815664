export const pubItems = [
  {
    id: '0',
    publication:
      'Spatial and temporal intercomparison of four global burned area products',
    year: '2018',
    location: ['Global'],
    tags: ['Burned Area', 'Intercomparision', 'MODIS', 'MERIS', 'SPOT'],
    DOI: 'https://doi.org/10.1080/17538947.2018.1433727',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '1',
    publication:
      'Assessing the Shape Accuracy of Coarse Resolution Burned Area Identifications',
    year: '2019',
    location: ['United States'],
    tags: ['Burned Area', 'MODIS', 'Landsat', 'Validation'],
    DOI: 'https://doi.org/10.1109/TGRS.2019.2943901',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '2',
    publication:
      'Validation of MCD64A1 and FireCCI51 cropland burned area mapping in Ukraine',
    year: '2021',
    location: ['Ukraine'],
    tags: ['Burned Area', ' Validation', ' MODIS', ' Cropland'],
    DOI: 'https://doi.org/10.1016/j.jag.2021.102443',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '3',
    publication:
      'Spatio-temporal patterns of optimal Landsat data for burn severity index calculations: Implications for high northern latitudes wildfire research',
    year: '2021',
    location: ['North America', ' High Northern Latitudes'],
    tags: [' Burned Area', ' Burn Severity', ' Landsat'],
    DOI: 'https://doi.org/10.1016/j.rse.2021.112393',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '4',
    publication:
      'A systematic evaluation of influence of image selection process on remote sensing-based burn severity indices in North American boreal forest and tundra ecosystems',
    year: '2020',
    location: ['Boreal', ' North America', ' High Northern Latitudes'],
    tags: ['Burned Area', ' Burn Severity', ' Landsat'],
    DOI: 'https://doi.org/10.1016/j.isprsjprs.2019.11.011',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '5',
    publication:
      'Mapping remote rural settlements at 30m spatial resolution using geospatial data-fusion',
    year: '2019',
    location: [' Myanmar'],
    tags: ['Active Fire', ' MODIS', ' VIIRS'],
    DOI: 'https://doi.org/10.1016/j.rse.2019.111386',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '6',
    publication:
      'Validation of GOES-16 ABI and MSG SEVIRI active fire products',
    year: '2019',
    location: ['Africa', ' North America', ' South America'],
    tags: [
      ' Active Fire',
      'Validation',
      'GOES-16 ABI',
      'MSG SEVIRI',
      'Geostationary',
    ],
    DOI: 'https://doi.org/10.1016/j.jag.2019.101928',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '7',
    publication:
      'Quantifying the variability of potential black carbon transport from cropland burning in Russia driven by atmospheric blocking events',
    year: '2018',
    location: ['Russia', ' Arctic'],
    tags: ['Active Fire', 'MODIS', 'Cropland', 'Atmospheric Transport'],
    DOI: 'https://doi.org/10.1088/1748-9326/aabf65',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '8',
    publication:
      'Quantifying the Potential for Low-Level Transport of Black Carbon Emissions from Cropland Burning in Russia to the Snow-Covered Arctic',
    year: '2017',
    location: ['Russia', ' Arctic'],
    tags: ['Active Fire', 'MODIS', 'Cropland', 'Atmospheric Transport'],
    DOI: 'https://doi.org/10.3389/feart.2017.00109',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '9',
    publication:
      'Land Management and the Impact of the 2010 Extreme Drought Event on the Agricultural and Ecological Systems of European Russia',
    year: '2016',
    location: ['Russia'],
    tags: ['Burned Area', ' Cropland'],
    DOI: 'https://doi.org/10.1007/978-3-319-42638-9_8',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '10',
    publication:
      'A remote sensing-based approach to estimating the fire spread rate parameter for individual burn patch extraction',
    year: '2022',
    location: ['Global'],
    tags: ['Active Fire', ' Fire Size', ' Spread Rate', ' MODIS'],
    DOI: 'https://doi.org/10.1080/01431161.2022.2027544',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '11',
    publication:
      'A MODIS-based burned area assessment for Russian croplands: Mapping requirements and challenges',
    year: '2016',
    location: ['Russia'],
    tags: ['Burned Area', ' MODIS', ' Cropland'],
    DOI: 'https://doi.org/10.1016/j.rse.2016.07.022',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '12',
    publication:
      'Global validation of the collection 6 MODIS burned area product',
    year: '2019',
    location: ['Africa'],
    tags: ['Burned Area', ' MODIS', ' Validation'],
    DOI: 'https://doi.org/10.1016/j.rse.2019.111490',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '13',
    publication:
      'Comment on Otón et al. Analysis of Trends in the FireCCI Global Long Term Burned Area Product (1982–2018). Fire 2021, 4, 74',
    year: '2022',
    location: ['Global'],
    tags: ['Burned Area', ' AVHRR'],
    DOI: 'https://doi.org/10.3390/fire5020052',
    type: 'Comment',
    link: 'view',
  },
  {
    id: '14',
    publication: 'Global patterns of interannual climate–fire relationships',
    year: '2018',
    location: ['Global'],
    tags: ['Burned Area', ' MODIS', ' Climate'],
    DOI: 'https://doi.org/10.1111/gcb.14405',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '15',
    publication:
      'Changes in Fire Activity in Africa from 2002 to 2016 and Their Potential Drivers',
    year: '2019',
    location: ['Africa'],
    tags: ['Burned Area', ' MODIS', ' Climate'],
    DOI: 'https://doi.org/10.1029/2019GL083469',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '16',
    publication:
      'Conflict and Climate: Drivers of Fire Activity in Syria in the Twenty-First Century',
    year: '2021',
    location: ['Middle East', 'Syria'],
    tags: ['Burned Area', ' MODIS', ' Conflict', ' Climate'],
    DOI: 'https://doi.org/10.1175/EI-D-21-0009.1',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '17',
    publication:
      'Letter to the editor on “Nonlinear dynamics of fires in Africa over recent decades controlled by precipitation”',
    year: '2022',
    location: ['Africa'],
    tags: ['Burned Area', ' MODIS'],
    DOI: 'https://doi.org/10.1111/gcb.16020',
    type: 'Letter',
    link: 'view',
  },
  {
    id: '18',
    publication:
      'Is global burned area declining due to cropland expansion? How much do we know based on remotely sensed data?',
    year: '2023',
    location: ['Africa', ' Global'],
    tags: ['Burned Area', 'MODIS'],
    DOI: 'https://doi.org/10.1080/01431161.2023.2174389',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '19',
    publication:
      'Fire Regions as Environmental Niches: A New Paradigm to Define Potential Fire Regimes in Africa and Australia',
    year: '2022',
    location: ['Africa', ' Australia'],
    tags: ['Burned Area', 'Active Fire', 'MODIS', 'Climate'],
    DOI: 'https://doi.org/10.1029/2021JG006694',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '20',
    publication: 'MODIS–Landsat fusion for large area 30 m burned area mapping',
    year: '2015',
    location: ['Global'],
    tags: ['Burned Area', 'Active Fire', 'MODIS', 'Fusion', 'Landsat'],
    DOI: 'https://doi.org/10.1016/j.rse.2015.01.022',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '21',
    publication:
      'Geostationary active fire products validation: GOES-17 ABI, GOES-16 ABI, and Himawari AHI',
    year: '2023',
    location: ['North America', ' South America', ' Asia', ' Australia'],
    tags: ['Active Fire', 'Validation', 'GOES', 'Himawari'],
    DOI: 'https://doi.org/10.1080/01431161.2023.2217983',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '22',
    publication: 'Bidi Bidi refugee settlement, Uganda case study',
    year: '2022',
    location: ['Africa', ' Uganda'],
    tags: ['Burned Area', ' Conflict', ' Sentinel-2'],
    DOI: 'https://doi.org/10.1088/1748-9326/ac6e48',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '23',
    publication:
      'Satellites may underestimate rice residue and associated burning emissions in Vietnam',
    year: '2017',
    location: ['Vietnam'],
    tags: ['Biomass Burning', 'SAR'],
    DOI: 'https://doi.org/10.1088/1748-9326/aa751d',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '24',
    publication:
      'An approach to estimate global biomass burning emissions of organic and black carbon from MODIS fire radiative power',
    year: '2009',
    location: ['Global'],
    tags: ['MODIS', 'FRE', 'Global Organic', 'Black Carbon', 'Emissions'],
    DOI: 'https://doi.org/10.1029/2008JD011188',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '25',
    publication: 'Estimating biomass consumed from fire using MODIS FRE',
    year: '2009',
    location: [''],
    tags: ['MODIS', 'FRE estimation'],
    DOI: 'https://doi.org/10.1029/2009GL038581',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '26',
    publication:
      'Multi-year black carbon emissions from cropland burning in the Russian Federation',
    year: '2012',
    location: ['Russian Federation'],
    tags: ['Cropland Burning', 'Aerosols'],
    DOI: 'https://doi.org/10.1016/j.atmosenv.2012.08.053',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '27',
    publication:
      'Integrated active fire retrievals and biomass burning emissions using complementary near-coincident ground, airborne and spaceborne sensor data',
    year: '2014',
    location: [''],
    tags: ['MODIS', 'Emissions', 'FRP', 'Validation'],
    DOI: 'https://doi.org/10.1016/j.rse.2013.10.010',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '28',
    publication:
      'An examination of fuel moisture, energy release and emissions during laboratory burning of live wildland fuels',
    year: '2019',
    location: [''],
    tags: ['Labratory', 'Fuel', 'Moisture', 'Emissions', 'FRE'],
    DOI: 'https://doi.org/10.1071/WF18084',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '29',
    publication:
      'Hotspot Analysis of Vegetation Fires and Intensity in the Indian Region',
    year: '2012',
    location: ['Indian Region'],
    tags: ['Hotspot analysis', 'FRP'],
    DOI: 'https://doi.org/10.1109/JSTARS.2012.2210699',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '30',
    publication:
      'Vegetation fires in the himalayan region Aerosol load, black carbon emissions and smoke plume heights',
    year: '2012',
    location: ['Himalaya'],
    tags: ['Cropland', 'Aerosols', 'FRP', 'Plume'],
    DOI: 'https://doi.org/10.1016/j.atmosenv.2011.11.009',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '31',
    publication:
      'The Collection 6 MODIS active fire detection algorithm and fire products',
    year: '2016',
    location: ['Global'],
    tags: ['MODIS', 'Active Fire', 'Collection 6'],
    DOI: 'https://doi.org/10.1016/j.rse.2016.02.054',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '32',
    publication:
      'Global distribution and seasonality of active fires as observed with the Terra and Aqua MODIS sensors.',
    year: '2006',
    location: ['Global'],
    tags: ['MODIS', 'Active Fire', 'Seasonality', 'Aqua', 'Terra'],
    DOI: 'https://doi.org/10.1029/2005JG000142',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '33',
    publication: 'An enhanced contextual fire detection algorithm for MODIS.',
    year: '2003',
    location: ['Global'],
    tags: ['MODIS', 'Active Fire'],
    DOI: 'https://doi.org/10.1016/S0034-4257(03)00184-6',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '34',
    publication:
      'An active-fire based burned area mapping algorithm for the MODIS sensor',
    year: '2009',
    location: ['Global'],
    tags: ['MODIS', 'Burned Area'],
    DOI: 'https://doi.org/10.1016/j.rse.2008.10.006',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '35',
    publication:
      'The Collection 5 MODIS Burned Area Product - Global Evaluation by Comparison with the MODIS Active Fire Product, 2008.',
    year: '2008',
    location: ['Global'],
    tags: ['MODIS', 'Burned Area', 'Collection 5'],
    DOI: 'https://doi.org/10.1016/j.rse.2008.05.013',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '36',
    publication:
      'Prototyping a global algorithm for systematic fire-affected area mapping using MODIS time series data',
    year: '2005',
    location: ['Global'],
    tags: ['MODIS', 'Burned Area', 'Algorithm'],
    DOI: 'https://doi.org/10.1016/j.rse.2005.04.007',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '37',
    publication:
      'GloCAB: global cropland burned area from mid-2002 to 2020',
    year: '2024',
    location: ['Global'],
    tags: ['Cropland', 'Burned Area', 'MODIS', 'MCD64A1', 'Global'],
    DOI: 'https://doi.org/10.5194/essd-16-867-2024',
    type: 'Publication',
    link: 'view',
  },
  {
    id: '38',
    publication:
      'Book Chapter: Mapping and Characterizing Fire',
    year: '2023',
    location: ['Global'],
    tags: ['Mapping', 'Biomass burning', 'Fire Monitoring'],
    DOI: 'https://doi.org/10.1002/9781119757030.ch3',
    type: 'Book Chapter',
    link: 'view',
  },
  {
    id: '39',
    publication:
      'FireCCILT11 artifacts may confound the link between biomass burning and infant mortality',
    year: '2023',
    location: ['NA'],
    tags: ['FireCCILT11'],
    DOI: 'https://www.pnas.org/doi/10.1073/pnas.2317759120',
    type: 'Letter',
    link: 'view',
  },
  {
    id: '40',
    publication:
      'Satellite artifacts modulate FireCCILT11 global burned area',
    year: '2023',
    location: ['Global'],
    tags: ['FireCCILT11', 'Artifacts'],
    DOI: 'https://doi.org/10.1038/s41467-023-36052-8',
    type: 'Publication',
    link: 'view',
  },

];
