import React from 'react'
import {Heading, Box, ChakraProvider, theme, useColorModeValue} from '@chakra-ui/react'

function TeamHeader() {
  return (
    <ChakraProvider theme={theme}>

    
    <Box w="100%" py={4} px={10} >
        <Heading as="h1" size="xl" textAlign="left" colorScheme={useColorModeValue('purple.400', 'green.900')}>
        About Us
        </Heading>
        <Heading as="h2" size="md" textAlign="left" paddingTop="5px" color="orange.600">
        UMD Fire Group 
        </Heading>
    </Box>
    </ChakraProvider>

  )
}

export default TeamHeader