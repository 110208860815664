import React from 'react';
import {
  ChakraProvider,
  theme,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex,
  IconButton,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ButtonGroup,
  Stack,
  StackDirection,
  LinkBox,
  LinkOverlay,
  Image,
} from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from './ColorModeSwitcher.js';
import { Link } from 'react-router-dom';
//import logoHeader from './data/img/WildfireHeader.png';

function Navbar() {
  const [size, setSize] = React.useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = newSize => {
    setSize(newSize);
    onOpen();
  };

  const sizes = ['xs'];

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Box>
          <Flex display={['none', 'none', 'flex', 'flex']}>
            <Box bg="orange.400" w="100%" p={4}>
              {/* <Stack direction='row' justifyContent={'space-between'}> */}
              {/* <Image boxSize='100px' src={logoHeader} /> */}
              <Tabs variant="soft-rounded" colorScheme="green" align="end">
                <TabList>
                  <Link to="/">
                    <Tab>Home</Tab>
                  </Link>
                  <Link to="/publications">
                    <Tab>Publications</Tab>
                  </Link>
                  <Link to="/datasets">
                    <Tab>Datasets</Tab>
                  </Link>
                  <Link to="/stats">
                    <Tab>Stats</Tab>
                  </Link>
                  <Link to="/team">
                    <Tab>Team</Tab>
                  </Link>

                  {/* <ColorModeSwitcher /> */}
                </TabList>
              </Tabs>
              {/* </Stack> */}
            </Box>
          </Flex>
        </Box>

        <Box align="right" w="100%" bg="orange.400" p={0.15}>
          {sizes.map(size => (
            <Button
              onClick={() => handleClick(size)}
              key={size}
              m={4}
              size="lg"
              aria-label="Navigation Menu"
              color={'gray.800'}
              bg={'green.100'}
              display={['flex', 'flex', 'none', 'none']}
            >
              {<HamburgerIcon />}
            </Button>
          ))}

          <Drawer
            onClose={onClose}
            isOpen={isOpen}
            size={size}
            colorScheme="tomato"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton size="lg" />
              <DrawerHeader>{`Menu`}</DrawerHeader>
              <DrawerBody>
                <Stack direction="column" spacing={4}>
                  <Link to="/">
                    <Button size="lg" width="100%" colorScheme="green">
                      Home
                    </Button>
                  </Link>
                  <Link to="/publications">
                    <Button size="lg" width="100%" colorScheme="green">
                      Publications
                    </Button>
                  </Link>
                  <Link to="/datasets">
                    <Button size="lg" width="100%" colorScheme="green">
                      Datasets
                    </Button>
                  </Link>
                  <Link to="/stats">
                    <Button size="lg" width="100%" colorScheme="green">
                      Stats
                    </Button>
                  </Link>
                  <Link to="/team">
                    <Button size="lg" width="100%" colorScheme="green">
                      Team
                    </Button>
                  </Link>

                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Navbar;
