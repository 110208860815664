import React from 'react'
import {Heading, Box, ChakraProvider, theme, useColorModeValue} from '@chakra-ui/react'

function AdjunctTeamHeader() {
  return (
    <ChakraProvider theme={theme}>

    
    <Box w="100%" py={4} px={10} >
        <Heading as="h2" size="md" textAlign="left" paddingTop="5px" color="orange.600">
        Adjunct Group Members
        </Heading>
    </Box>
    </ChakraProvider>

  )
}

export default AdjunctTeamHeader