import React from "react"
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
  ChakraProvider,
  theme
} from "@chakra-ui/react"

import giglio from "./data/img/giglio.jpg"
import roy from "./data/img/roy.jpg"
import fireccilt11 from "./data/img/firecci_fig2.png"

const BlogTags = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        )
      })}
    </HStack>
  )
}

export const BlogAuthor = props => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        //boxSize="50px"
        height="45px"
        width="35px"
        src={giglio}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Image
        borderRadius="full"
        //boxSize="50px"
        height="45px"
        width="35px"
        src={roy}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">D. Roy</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  )
}

const LandingNews = () => {
  return (
    <ChakraProvider theme={theme}>
    <Container maxW={"7xl"} p="12">
      <Heading as="h1">Latest News</Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
          >
            <Link href={"https://doi.org/10.1073/pnas.2317759120"} textDecoration="none" _hover={{ textDecoration: "none" }}>
              <Image
                borderRadius="xs"
                src={fireccilt11}
                alt="Subsets of the FireCCILT11 monthly 0.05 degree BA product"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          <BlogTags tags={["Open Letter"]} />
          <Heading marginTop="1">
            <Link href={"https://doi.org/10.1073/pnas.2317759120"} textDecoration="none" _hover={{ textDecoration: "none" }}>
            ESA Burned area product artifacts may confound the link between biomass burning and infant mortality
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue("gray.700", "gray.200")}
            fontSize="lg"
          >
            Open letter to Pullabhotla et al. use of the FireCCILT11 biomass burning exposure data due to known flaws in the product, such as inconsistencies with higher quality data and blocky artifacts, casting doubt on the validity of the study's findings regarding infant mortality attributable to biomass burning exposure. The letter underscores the necessity of addressing these errors explicitly in global and long-term analyses to ensure accurate results.
          </Text>
          <BlogAuthor name="L. Giglio" date={new Date("2023-12-07T19:01:27Z")} />
        </Box>
      </Box>
      <Divider marginTop="5" />
      {/* <Heading as="h2" marginTop="5">
        Latest News
      </Heading>
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Image
                  transform="scale(1.0)"
                  src={
                    "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
                  }
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)"
                  }}
                />
              </Link>
            </Box>
            <BlogTags tags={["Engineering", "Product"]} marginTop="3" />
            <Heading fontSize="xl" marginTop="2">
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                Some blog title
              </Link>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Text>
            <BlogAuthor
              name="John Doe"
              date={new Date("2021-04-06T19:01:27Z")}
            />
          </Box>
        </WrapItem>
      </Wrap> */}
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">Fire Detection</Heading>
        <Text as="p" fontSize="lg">
        Fire is a complex biophysical process with multiple direct and indirect effects on the atmosphere, the biosphere and the hydrosphere. Moreover, it is now widely recognized that, in some fire prone environments, fire disturbance is essential to maintain the ecosystem in a state of equilibrium.

The MODIS active fire and burned area products contain information unique to understanding the timing and spatial distribution of fires and their characteristics.

The MODIS Standard Fire products provide an important contribution to the <Link color="green.500" href={'https://lcluc.umd.edu/'} isExternal> NASA Land Use and Land Cover Program </Link> and the international <Link color="green.500" href={'https://start.org/programs/gofc-gold/'} isExternal>Global Observation of Forest Cover (GOFC) Project </Link>.
        </Text>
        <Text as="p" fontSize="lg">
        Satellites have a role to play in detecting, monitoring and characterizing fires. 
        There are satellite systems currently in orbit that provide information on 
        different fire characteristics: location and timing of active fires, burned area, 
        areas that are dry and susceptible to wildfire outbreaks, and pyrogenic 
        trace gas and aerosol emissions. These satellite systems have different capabilities 
        in terms of spatial resolution, sensitivity, spectral bands, and times and frequencies of 
        overpasses, but none of the sensing systems prior to NASA's MODIS included fire monitoring in their design.
        </Text>
        {/* <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text>
        <Text as="p" fontSize="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          condimentum quam arcu, eu tempus tortor molestie at. Vestibulum
          pretium condimentum dignissim. Vestibulum ultrices vitae nisi sed
          imperdiet. Mauris quis erat consequat, commodo massa quis, feugiat
          sapien. Suspendisse placerat vulputate posuere. Curabitur neque
          tortor, mattis nec lacus non, placerat congue elit.
        </Text> */}
      </VStack>
    </Container>
    </ChakraProvider>
  )
}

export default LandingNews
