import { Box, Typography, Button, Chip, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, purple, cyan, red, orange, blueGrey } from '@mui/material/colors';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { pubItems } from './data/PubsData.js';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LaunchIcon from '@mui/icons-material/Launch';
import * as React from 'react';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

// //const tagsSortComparator = (tags1, tags2) => {
//   return tags1.length - tags2.length
// }

// //const tagsFilterOperators = getGridSingleSelectOperators()
//   .filter(operator => operator.value === "isAnyOf")
//   .map(operator => {
//     const newOperator = { ...operator }
//     const newGetApplyFilterFn = (filterItem, column) => {
//       return params => {
//         let isOk = true
//         filterItem?.value?.forEach(fv => {
//           isOk = isOk && params.value.includes(fv)
//         })
//         return isOk
//       }
//     }
//     newOperator.getApplyFilterFn = newGetApplyFilterFn
//     return newOperator
//   })


// const theme = createTheme({
//   palette: {
//     primary: blue,
//     secondary: purple, success: cyan,
//     error: blueGrey,
//     warning: red,
//     info: orange,

//   },
// });

const getColorForTag = (tag) => {

  const tagColors = {
    'Cropland': 'primary',
    'Burned Area': 'secondary',
    'MODIS': 'success',
    'MCD64A1': 'error',
    'Global': 'warning',
    'Education': 'info',
    'Other': 'default',
    // Add more mappings as needed
  };

  return tagColors[tag] || 'default'; // Default to 'default' if the tag is not in the mapping
};


const PubsGrid = () => {
  const columns = [
    {
      field: 'year',
      headerName: 'Year',
      minwidth: 20,
    },
    { field: 'publication', headerName: 'Publication', width: 400 },
    {
      field: 'location',
      headerName: 'Location',
      // flex: 1,
      minwidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 500,
      type: 'singleSelect',
      //valueOptions: [...new Set(pubItems.map(o => o.tags).flat())],
      renderCell: params => (
        <Stack direction="row" spacing={0.15}>
          {params.row.tags.map(tag => (
            <Chip label={tag} color={getColorForTag(tag)} />
          ))}
        </Stack>
      ),
      // sortComparator: tagsSortComparator,
      // filterOperators: tagsFilterOperators
    },
    {
      field: 'DOI',
      headerName: 'DOI',
      width: 200,
    },
    {
      field: 'link',
      headerName: 'Action',
      width: 100,
      renderCell: ({ row: { link, DOI } }) => {
        return (
          <Button href={DOI} target={DOI}>
            {link === 'link' && <LinkIcon />}
            {link === 'unavailable' && <LinkOffIcon />}
            {link === 'view' && <LaunchIcon />}
            <Typography color="orange" sx={{ ml: '5px' }}>
              {link}
            </Typography>
          </Button>
        );
      },
    },
  ];


  return (
    // <ThemeProvider theme={theme}>
    <Box m="20px">
      <DataGrid
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        rows={pubItems}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'year',
                sort: 'desc',
              },
            ],
          },
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              debounceMs: 500,
            },
          },
        }}
      />
    </Box>
    //</ThemeProvider> 
  );
};

export default PubsGrid;
