"use client"
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
    ChakraProvider
} from "@chakra-ui/react"
import {
    FcAbout,
    FcAssistant,
    FcCollaboration,
    FcDonate,
    FcManager,
    FcGlobe
} from "react-icons/fc"

const Card = ({ heading, description, icon, pdfPath }) => {
    return (
        <Box
            maxW={{ base: "full", md: "275px" }}
            w={"full"}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}
        >
            <Stack align={"start"} spacing={2}>
                <Flex
                    w={16}
                    h={16}
                    align={"center"}
                    justify={"center"}
                    color={"white"}
                    rounded={"full"}
                    bg={useColorModeValue("gray.100", "gray.700")}
                >
                    {icon}
                </Flex>
                <Box mt={2}>
                    <Heading size="md">{heading}</Heading>
                    <Text mt={1} fontSize={"sm"}>
                        {description}
                    </Text>
                </Box>
                <Button variant={"link"} colorScheme={"blue"} size={"sm"} as="a" href={pdfPath} download>
                    User Guide
                </Button>
            </Stack>
        </Box>
    )
}

export default function VIIRSAFProducts() {
    return (
        <ChakraProvider>
            <Box p={4}>
                <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
                    <Heading fontSize={{ base: "2xl", sm: "4xl" }} fontWeight={"bold"}>
                        VIIRS Active Fire Products
                    </Heading>
                    <Text color={"gray.600"} fontSize={{ base: "sm", sm: "lg" }}>
                    The Visible Infrared Imaging Radiometer Suite (VIIRS) Version 2 release includes data products from both the Suomi National Polar-orbiting Partnership (Suomi NPP) 
                    satellite and the NOAA-20 satellite which was previously called Joint Polar Satellite System-1 (JPSS-1).
                    </Text>
                </Stack>

                <Container maxW={"5xl"} mt={12}>
                    <Flex flexWrap="wrap" gridGap={6} justify="center">
                        <Card
                            heading={"VNP14IMG"}
                            icon={<Icon as={FcGlobe} w={10} h={10} />}
                            description={
                                "(VNP14IMG) Version 2 product is produced in 6-minute temporal satellite increments (swaths) at 375 meter resolution from the VIIRS sensor aboard the NASA/NOAA Suomi National Polar-orbiting Partnership (Suomi NPP) satellite."
                            }
                            pdfPath={"/pdfs/VIIRS_C2_AF-375m_User_Guide.pdf"}
                        />
                        <Card
                            heading={"VJ114IMG"}
                            icon={<Icon as={FcGlobe} w={10} h={10} />}
                            description={
                                "The VJ114IMG product includes 26 science dataset layers to analyze key factors in fire detection, including atmospheric conditions (e.g., radiance, solar zenith angle, brightness temperature) and fuel type for the event."
                            }
                            pdfPath={"/pdfs/VIIRS_C2_AF-375m_User_Guide.pdf"}
                        />
                    </Flex>
                </Container>
            </Box>
        </ChakraProvider>
    )
}
