import React from 'react'
import {Heading, Box, ChakraProvider, theme, useColorModeValue} from '@chakra-ui/react'

function PubsHeader() {
  return (
    <ChakraProvider theme={theme}>

    
    <Box w="100%" p={4} >
        <Heading as="h1" size="xl" textAlign="left" colorScheme={useColorModeValue('purple.400', 'green.900')}>
        Publications
        </Heading>
        <Heading as="h2" size="md" textAlign="left" paddingTop="5px" color="orange.600">
            Peer-reveiwed and published by UMD Fire Group
        </Heading>
    </Box>
    </ChakraProvider>

    
  )
}

export default PubsHeader