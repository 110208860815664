import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  IconButton,

  useColorModeValue,
  ChakraProvider,
  theme,

  LinkOverlay,
  LinkBox,
  SimpleGrid,
} from '@chakra-ui/react';

import { FaUniversity, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import giglio from './data/img/giglio.jpg';
import evan from './data/img/evan.jpg';
import maria from './data/img/maria.jpg';
import mike from './data/img/mike.jpg';
import joanne from './data/img/joanne.jpg';
import fernanda from './data/img/fernanda.jpg';

export default function TeamCard() {
  return (
    <ChakraProvider theme={theme}>
      <SimpleGrid
        columns={{ sm: 2, md: 3 }}
        minChildWidth={'350px'}
        spacing={6}
      >
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={giglio}
              alt={'Louis Giglio'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Louis Giglio
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Research Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Giglio's interests include remote sensing of active fires and
              burned area, global fire emissions, and satellite direct broadcast
              applications.
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/giglio/louis#tab-biography">
                  <IconButton
                    aria-label="Giglio UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Giglio linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Giglio twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={evan}
              alt={'Evan Ellicott'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Evan Ellicott
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Associate Research Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Ellicott's interests include the application of geospatial
              technologiesto investigate the interactive and dynamic nature of
              landscape ecology and interactions with humans.
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/ellicott/evan#tab-biography">
                  <IconButton
                    aria-label="Ellicott UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Ellicott linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Ellicott twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={joanne}
              alt={'Joanne Hall'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Joanne V. Hall
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Assistant Research Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Hall's current research interests lie in the broad areas of
              satellite mapping of land-cover and land-use change, with an
              emphasis on active fire and burned area mapping.
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/hall/joanne">
                  <IconButton
                    aria-label="Hall UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <LinkBox>
                <LinkOverlay href="https://www.linkedin.com/in/joanne-hall-phd">
                  <IconButton
                    aria-label="Hall linkedin"
                    size="lg"
                    icon={<FaLinkedinIn />}
                  />
                </LinkOverlay>
              </LinkBox>

              <IconButton
                aria-label="Hall twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={mike}
              alt={'Michael Humber'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Michael Humber
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Associate Research Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Humber leads technical development of the Crop Monitors and
              works with Dr. Louis Giglio to maintain and distribute GIS-ready
              products derived from the MODIS Collection 6 MCD64A1 Burned Area
              products.
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/humber/michael">
                  <IconButton
                    aria-label="Humber UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <LinkBox>
                <LinkOverlay href="https://www.linkedin.com/in/mike-humber-04896a240/">
                  <IconButton
                    aria-label="Humber linkedin"
                    size="lg"
                    icon={<FaLinkedinIn />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Humber twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={maria}
              alt={'Maria Zubkova'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Maria Zubkova
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Assistant Research Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Zubkova's research interests lie in understanding the complex
              interaction between climate, humans, and fire. Specifically, her
              current research focuses on recent changes in global fire activity
              and their relationship to climate change, population growth, and
              human conflicts.
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/zubkova/maria">
                  <IconButton
                    aria-label="Zubkova UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <LinkBox>
                <LinkOverlay href="https://www.linkedin.com/in/mzubkova/">
                  <IconButton
                    aria-label="Zubkova linkedin"
                    size="lg"
                    icon={<FaLinkedinIn />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Zubkova twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={fernanda}
              alt={'Fernanda Argueta'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Fernanda Argueta
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              GIS Specialist
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Ms. Argueta has assisted in research focused on the improvement of
              global cropland burned area using remote-sensing techniques and
              the validation of geostationary active fire products.
            </Text>
            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <IconButton
                aria-label="Argueta UMD website"
                size="lg"
                icon={<FaUniversity />}
                isDisabled="true"
              />
              <LinkBox>
                <LinkOverlay href="https://www.linkedin.com/in/fernanda-argueta/">
                  <IconButton
                    aria-label="Argueta linkedin"
                    size="lg"
                    icon={<FaLinkedinIn />}
                  />
                </LinkOverlay>
              </LinkBox>

              <IconButton
                aria-label="Argueta twitter"
                size="lg"
                icon={<FaTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
      </SimpleGrid>
    </ChakraProvider>
  );
}
