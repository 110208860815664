import {
  LinkBox,
  LinkOverlay,
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'

//import viirsworldview from './data/img/viirs_worldview.png'
import viirs_zoomin from './data/img/viirs_zoomin.jpg'

const VIIRSCard = () => {
  return (
    <Flex
      w={"full"}
      h={"50vh"}
      backgroundImage={
        viirs_zoomin
      }
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
      borderRadius={"lg"}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
        borderRadius={"lg"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={6}>
          <Text
            color={"white"}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
          >
            VIIRS
          </Text>
          <Stack direction={"row"}>
            <LinkBox>
              <LinkOverlay href="/viirs">
                <Button
                  bg={"green.400"}
                  rounded={"full"}
                  color={"white"}
                  _hover={{ bg: "green.500" }}
                >
                  Learn more
                </Button>
              </LinkOverlay>
            </LinkBox>
            <LinkBox>
              <LinkOverlay href='/publications'>
                <Button
                  bg={"whiteAlpha.300"}
                  rounded={"full"}
                  color={"white"}
                  _hover={{ bg: "whiteAlpha.500" }}
                >
                  Explore Publications
                </Button>
              </LinkOverlay>
            </LinkBox>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  )
}

export default VIIRSCard