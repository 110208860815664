import {
    Box,
    SimpleGrid,
    ChakraProvider,
    Center,
    useColorModeValue,
} from '@chakra-ui/react';

import MODISCard from './MODISCard';
import VIIRSCard from './VIIRSCard';

export default function DatasetCardHolder() {
    return (
        <ChakraProvider>
            <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
                spacing={6}
            >
                <Center py={6} px="50px">
                    <Box
                        width={{ base: 'full', md: 'auto', sm: 'auto', xs: 'auto' }}
                        height={'full'}

                        minW={'100%'}
                        bg={useColorModeValue('gray.200', 'gray.900')}
                        // boxShadow={'2xl'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}
                    >
                    <MODISCard />
                    </Box>
                </Center>
                <Center py={6} px="50px">
                    <Box
                        width={{ base: 'full', md: 'auto', sm: 'auto', xs: 'auto' }}
                        height={'full'}

                        minW={'100%'}
                        bg={useColorModeValue('gray.200', 'gray.900')}
                        // boxShadow={'2xl'}
                        rounded={'lg'}
                        p={6}
                        textAlign={'center'}
                    >
                    <VIIRSCard />
                    </Box>
                </Center>
            </SimpleGrid>
        </ChakraProvider>
    );
}