import React from 'react'
import CaptionCarousel from './components/Carousel.js'
import Footer from './components/Footer.js'
import LandingNews from './components/LandingNews.js'
function LandingPage() {
  return (
    <div
      sx={{
        '& .scrollDiv': {
          height: 'auto',
          maxHeight: '100%',
          overflow: 'auto',
        },
      }}
    >
      <CaptionCarousel />
      <LandingNews />
      <Footer /> 
    </div>
  )
}

export default LandingPage