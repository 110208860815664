export const productItems = [
  {
    id: '0',
    product: 'VNP14IMG',
    version: '2',
    description: 'Collection 2 Visible Infrared Imaging Radiometer Suite (VIIRS) 375-m Active Fire Product.',
    mission: ['VIIRS'],
    tags: ['Active Fire', 'Global'],
    userguidelink: 'VIIRS_C2_AF-375m_User_Guide.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/vnp14imgv002/',
    link: 'access data',
  },
  {

    id: '1',
    product: 'VNP64A1',
    version: '2',
    description: 'Collection 2 VIIRS Burned Area Product',
    mission: ['VIIRS'],
    tags: ['Burned Area', 'Global'],
    userguidelink: 'VIIRS_C2_BA_User_Guide.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/vnp64a1v001/',
    link: 'access data',

  },
  {
    id: '2',
    product: 'VJ114IMG',
    version: '2',
    description: 'Collection 2 Visible Infrared Imaging Radiometer Suite (VIIRS) 375-m Active Fire Product.',
    mission: ['VIIRS'],
    tags: ['Active Fire', 'Global'],
    userguidelink: 'VIIRS_C2_AF-375m_User_Guide.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/vj114imgv002/',
    link: 'access data',

  },
  {
    id: '3',
    product: 'MOD14',
    version: '6',
    description: 'The Moderate Resolution Imaging Spectroradiometer (MODIS) Thermal Anomalies and Fire MOD14 Version 6 product is produced daily in 5-minute temporal satellite increments (swaths).',
    mission: ['MODIS'],
    tags: ['Terra'],
    userguidelink: 'https://modis-fire.umd.edu/files/MODIS_C6_C6.1_Fire_User_Guide_1.0.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/mod14v006/',
    link: 'access data',
  },
  {
    id: '4',
    product: 'MOD14',
    version: '6.1',
    description: 'The Moderate Resolution Imaging Spectroradiometer (MODIS) Thermal Anomalies and Fire MOD14 Version 6.1 product is produced daily in 5-minute temporal satellite increments (swaths). The MOD14 product is used to generate all of the higher level fire products, but can also be used to identify fires and other thermal anomalies, such as volcanoes.',
    mission: ['MODIS'],
    tags: ['Terra'],
    userguidelink: 'https://lpdaac.usgs.gov/documents/1005/MOD14_User_Guide_V61.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/mod14v061/',
    link: 'access data',
  },
  {
    id: '5',
    product: 'MYD14',
    version: '6',
    description: 'The Aqua Moderate Resolution Imaging Spectroradiometer (MODIS) Thermal Anomalies and Fire MYD14 Version 6 product is produced daily in 5-minute temporal satellite increments (swaths).',
    mission: ['MODIS'],
    tags: ['Aqua'],
    userguidelink: 'https://lpdaac.usgs.gov/documents/1005/MOD14_User_Guide_V61.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/myd14v006/',
    link: 'access data',
  },
  {
    id: '6',
    product: 'MYD14',
    version: '6.1',
    description: 'The Aqua Moderate Resolution Imaging Spectroradiometer (MODIS) Thermal Anomalies and Fire MYD14 Version 6.1 product is produced daily in 5-minute temporal satellite increments (swaths).',
    mission: ['MODIS'],
    tags: ['Aqua'],
    userguidelink: 'https://lpdaac.usgs.gov/documents/1005/MOD14_User_Guide_V61.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/myd14v061/',
    link: 'access data',
  },
  {
    id: '7',
    product: 'MCD64',
    version: '6',
    description: 'The Terra and Aqua combined MCD64A1 Version 6 Burned Area data product is a monthly, global gridded 500 meter (m) product containing per-pixel burned-area and quality information.',
    mission: ['MODIS'],
    tags: ['Aqua', 'Terra'],
    userguidelink: 'https://modis-fire.umd.edu/files/MODIS_C6_C6.1_Fire_User_Guide_1.0.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/mcd64a1v006/',
    link: 'access data',
  },
  {
    id: '8',
    product: 'MCD64',
    version: '6.1',
    description: 'The Terra and Aqua combined MCD64A1 Version 6.1 Burned Area data product is a monthly, global gridded 500 meter (m) product containing per-pixel burned-area and quality information.',
    mission: ['MODIS'],
    tags: ['Aqua', 'Terra'],
    userguidelink: 'https://modis-fire.umd.edu/files/MODIS_C6_C6.1_Fire_User_Guide_1.0.pdf',
    userguide: 'user guide',
    lpdaaclink: 'https://lpdaac.usgs.gov/products/mcd64a1v061/',
    link: 'access data',
  },
  {
    id: '9',
    product: 'MCD14ML',
    version: '6.1',
    description: 'Moderate Resolution Imaging Spectroradiometer (MODIS) Thermal Anomalies / Fire locations - Collection 6/61',
    mission: ['MODIS'],
    tags: ['Aqua'],
    userguidelink: 'https://www.earthdata.nasa.gov/learn/find-data/near-real-time/firms/mcd14ml',
    userguide: 'user guide',
    lpdaaclink: 'https://firms.modaps.eosdis.nasa.gov/download/',
    link: 'access data',
  },
  {
    id: '10',
    product: 'GloCAB: Global Cropland Area Burned',
    version: '',
    description: 'The dataset provides monthly, cropland burned area files at 0.25-degree spatial resolution from July 2002 - December 2020 for our upper (high) and lower (low) estimates.',
    mission: ['NA'],
    tags: ['Burned Area', 'Cropland'],
    userguidelink: 'https://doi.org/10.5281/zenodo.7860452',
    userguide: 'user guide',
    lpdaaclink: 'https://doi.org/10.5281/zenodo.7860452',
    link: 'access data',
  },
  {
    id: '11',
    product: 'GloCAB: Cropland Field Boundary Dataset',
    version: '',
    description: 'This cropland field boundary vector dataset consists of 190,832 manually-digitized cropland field boundaries.',
    mission: ['NA'],
    tags: ['Vectors', 'Cropland'],
    userguidelink: 'https://doi.org/10.5281/zenodo.10479122',
    userguide: 'user guide',
    lpdaaclink: 'https://doi.org/10.5281/zenodo.10479122',
    link: 'access data',
  },
  {
    id: '12',
    product: 'Crop Residue Burning Emission Coefficients',
    version: '',
    description: 'crop-specific coefficients related to the burning of agricultural waste or “residue”: Emission Factors, Combustion Efficiencies, and Crop-to-Residue Ratios.',
    mission: ['NA'],
    tags: ['Emission', 'Agriculture', 'Crop Burning'],
    userguidelink: 'https://doi.org/10.5281/zenodo.10479122',
    userguide: 'user guide',
    lpdaaclink: 'https://doi.org/10.5281/zenodo.10479122',
    link: 'access data',
  }


];
